<template>
    <div class="cart-item">
        <div class="name">
            {{item.title}}
        </div>
        <div class="price">
            {{$n(itemPriceGross * item.quantity, 'currency')}}
        </div>

        <div class="bottom">
            <div class="btnContainer">
                <button class="deleteBtn" @click="removeItem()" v-if="!readonly">
                    {{ $t('cart-btn-delete') }}
                </button>
            </div>
            <div class="qtyButtons" v-if="hasQtyButtons">
                <button @click="changeQty('minus')">
                    <font-awesome-icon icon="fa-solid fa-minus" />
                </button>
                <div class="num">{{ item.quantity }}</div>
                <button  @click="changeQty('plus')">
                    <font-awesome-icon icon="fa-solid fa-plus" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cart-item',
    props: {
        item: {
            type: Object
        },
        hasQtyButtons: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        itemPriceGross() {
            return this.item.price;
        }
    },
    methods: {
        removeItem() {
            let quantity = 0;
            this.$store.dispatch('updateProductQuantityInCart', {product: this.item, quantity})
        },
        changeQty(way) {
            let quantity = this.item.quantity;
            switch(way) {
                case 'plus':
                    quantity += 1
                break;
                case 'minus':
                    quantity -= 1
                    break
            }

            this.$store.dispatch('updateProductQuantityInCart', {product: this.item, quantity})
        },
    }
}
</script>
