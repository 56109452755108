<template>
    <div :class="{'food-content-item': true, 'featured-item': featured, 'invisible': invisible}" @click="openDetails()">
        <div class="description">
            <h4>{{ foodData.title }}</h4>
            <div class="lead" v-html="leadText"></div>
            <div class="price" v-if="showPrice">
                {{$n(price, 'currency')}}
            </div>
            <div class="actions">
                <a href="#" v-if="canChange">{{ $t('food-item-change-up') }}</a>

                <span class="status" v-if="status == 'ordered'">
                    {{ $t('food-item-ordered') }}
                    <font-awesome-icon icon="fa-solid fa-circle-check" />
                </span>
            </div>
        </div>
        <div class="image">
            <div class="featured-text" v-if="featured">
                {{ $t('food-item-featured')}}
            </div>
            <smart-image :img_key="foodData.image_key" :size="'medium'"></smart-image>
        </div>
    </div>
</template>

<script>
import smartImage from '../smart-image.vue';
import { marked } from 'marked';

export default {
  components: { smartImage },
    name: 'food-item',
    props: {
        status: {
            type: String,
            default: 'watch'
        },
        canChange: {
            type: Boolean,
            default: false
        },
        showPrice: {
            type: Boolean,
            default: true
        },
        foodData: {
            type: Object,
            required: true
        },
        featured: {
            type: Boolean,
            default: false
        },
        invisible: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        leadText() {
            if(this.foodData) {
                return marked.parse(this.foodData.lead);
            }

            return '';
        },
        price() {
            return this.foodData.price;
        }
    },
    methods: {
        openDetails() {
            this.$emit('open-details', this.foodData);
        },
    }
}
</script>