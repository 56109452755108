<template>
    <div class="cart sidebar">
        <h4>{{ $t('cart-title') }}</h4>

        <cart-item v-for="(row, ckey) in cartItems"
        :key="'cart-item-'+ckey"
        :item="row"
        :hasQtyButtons="true"
        :readonly="false"></cart-item>
        <table>
            <tbody>
                <tr>
                    <td>{{ $t('cart-total-gross-price') }}</td>
                    <td>
                        <strong>{{$n(grossPrice, 'currency')}}</strong>
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-if="isPreorder">
            <button class="orderBtn food-drink" @click="eventSelectTab('drink')" v-if="productType == 'food'">
                <span>
                    {{ $t('go-to-drinks')}}
                </span>
            </button>
            <button class="orderBtn food-drink" @click="eventSelectTab('food')" v-if="productType == 'drink'">
                <span>
                    {{ $t('back-to-foods')}}
                </span>
            </button>
            <button class="orderBtn" @click="orderConfirm()" v-if="!readonly && cartItems.length > 0">
                <span>
                    {{ $t('cart-btn-confirm-preorder')}}
                </span>
            </button>
        </div>
        <div v-else>
            <button class="orderBtn" @click="orderConfirm()" v-if="!readonly && cartItems.length > 0">
                <span>
                    {{ $t('cart-btn-confirm-order') }}
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import CartItem from "./cart-item.vue";

export default {
  components: { CartItem },
    name: 'cart',
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        isPreorder: {
            type: Boolean,
            default: true
        },
        productType: {
            type: String,
            default: 'food'
        }
    },
    computed: {
        netPrice() {
            return this.$store.getters.totalPrice;
        },
        vatAmount() {
            return Math.round(this.grossPrice - this.netPrice);
        },
        grossPrice() {
            return this.$store.getters.totalPrice;
        },
        cart() {
            return this.$store.state.cart;
        },
        cartItems() {
            let items = [];

            if(this.cart.items) {
                items = this.cart.items;
            }

            return items;
        },
    },
    methods: {
        formatPrice(value) {
            return value + ' Ft';
        },
        orderConfirm() {
            if(this.cartItems) {
                this.$router.push('/overview');
            }
        },
        eventSelectTab(productType) {
            this.$emit('changeProductType', productType);
        }
    }
}
</script>
