<template>
    <div class="wrapper event-details">
        <div class="details-top">
            <div class="left">
                <div class="date">
                    <div class="circle">
                        <font-awesome-icon icon="fa-solid fa-calendar" />
                    </div>
                    <span>{{ eventDate }}</span>
                </div>
                <div class="recommendations">
                    <div class="circle orange">
                        <font-awesome-icon icon="fa-solid fa-arrow-down" />
                    </div>
                    <span @click="downloadRecommendations()">{{$t('download-recommendations')}}</span>
                </div>
            </div>
            <div class="right description">
                <span class="title">{{$t('event-description')}}</span>
                <p>
                    {{ event.event.description }}
                </p>
            </div>
        </div>

        <div class="content">
            <div class="left">
                <div class="headline">
                    <h1>{{$t('preorder')}}</h1>
                </div>

                
                <div class="tabs">
                    <ul>
                        <li :class="{'selected' : pRow.type == selectedProductType}"
                        v-for="(pRow, pKey) in productTypes"
                        :key="'product-type-'+pKey"
                        >
                            <a @click="selectProductType(pRow.type)" v-t="pRow.label"></a>
                        </li>
                    </ul>
                </div>

                <div class="food-content-list" v-if="event.buffetFoods">
                    <div class="package" v-if="foodList && selectedProductType == 'food'">

                        <div class="food-category-carousel">
                            <div :class="{
                                'item': true,
                                'visible': hasFeaturedItemInType('food'),
                                'active': true
                            }" id="link-featured-food" @click="scrollToCategory('featured-food')">
                                <button>{{ $t('food-item-featured') }}</button>
                            </div>

                            <div :class="{
                                'item': true,
                                'visible': hasFoodProductInCategory(dkName)
                            }" v-for="(dkName, dk) in FOOD_CATEGORY" :key="dk" :id="'link-' + dkName"
                                @click="scrollToCategory(dkName)">
                                <button>{{ $t('food-' + dkName) }}</button>
                            </div>
                        </div>

                        <div class="product-category" v-if="hasFeaturedItemInType('food')">
                            <div id="featured-food">
                                <h3 class="categoryName">{{ $t('food-item-featured') }}</h3>

                                <food-item @open-details="openModal" :foodData="row.food" :featured="row.featured"
                                    v-for="(row, fkey) in featuredProductList" :key="'food-' + fkey"></food-item>
                            </div>
                        </div>

                        <div class="product-category" v-for="(dkName, dk) in FOOD_CATEGORY" :key="dk + '2'">
                            <div v-if="hasFoodProductInCategory(dkName)" :id="dkName">
                                <h3 class="categoryName">{{ $t('food-' + dkName) }}</h3>

                                <food-item @open-details="openModal" :foodData="row.food" :featured="row.featured"
                                    :invisible="row.food.foodCategory !== dkName" v-for="(row, fkey) in foodList"
                                    :key="'food-' + fkey"></food-item>
                            </div>
                        </div>
                    </div>
                    <div class="package" v-if="drinkList && selectedProductType == 'drink'">
                        
                        <div class="food-category-carousel">
                            <div :class="{
                                'item': true,
                                'visible': hasFeaturedItemInType('drink'),
                                'active': true
                            }" id="link-featured-drink" @click="scrollToCategory('featured-drink')">
                                <button>{{ $t('food-item-featured') }}</button>
                            </div>

                            <div :class="{
                                'item': true,
                                'visible': hasProductInCategory(dkName)
                            }" v-for="(dkName, dk) in DRINK_CATEGORY" :key="dk" :id="'link-' + dkName"
                                @click="scrollToCategory(dkName)">
                                <button>{{ $t('drink-' + dkName) }}</button>
                            </div>
                        </div>

                        <div class="product-category" v-if="hasFeaturedItemInType('drink')">
                            <div id="featured-drink">
                                <h3 class="categoryName">{{ $t('food-item-featured') }}</h3>

                                <food-item @open-details="openModal" :foodData="row.food" :featured="row.featured"
                                    v-for="(row, fkey) in featuredProductList" :key="'food-' + fkey"></food-item>
                            </div>
                        </div>

                        <div class="product-category" v-for="(dkName, dk) in DRINK_CATEGORY" :key="dk + '2'">
                            <div v-if="hasProductInCategory(dkName)" :id="dkName">
                                <h3 class="categoryName">{{ $t('drink-' + dkName) }}</h3>

                                <food-item @open-details="openModal" :foodData="row.food" :featured="row.featured"
                                    :invisible="row.food.category !== dkName" v-for="(row, fkey) in drinkList"
                                    :key="'food-' + fkey"></food-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="right">
                <cart
                    :productType="selectedProductType"
                    @changeProductType="selectProductType"
                ></cart>
            </div>
        </div>

        <vue-final-modal v-model="modalOpened">
            <div class="modal-product" v-if="selectedProduct">
                <div class="close-btn">
                    <div class="circle">
                        <button @click="closeModal()">
                            <font-awesome-icon icon="fa-solid fa-xmark" />
                        </button>
                    </div>
                </div>
                <div class="image">
                    <smart-image :img_key="selectedProduct.image_key" :size="'medium'"></smart-image>
                </div>
                <div class="content-container">
                    <div class="content">
                        <div class="name">
                            <strong>{{ selectedProduct.title }}</strong>
                        </div>
                        <div class="price">
                            {{$n(getSelectedProductPrice(selectedProduct), 'currency')}}
                        </div>

                        <div class="description" v-html="productModalLead"></div>
                    </div>
                    <div class="bottom">
                        <div class="qtyButtons">
                            <button @click="changeQty('minus')">
                                <font-awesome-icon icon="fa-solid fa-minus" />
                            </button>
                            <div class="num">{{ quantity }}</div>
                            <button @click="changeQty('plus')">
                                <font-awesome-icon icon="fa-solid fa-plus" />
                            </button>
                        </div>
                        <div class="addToCart">
                            <button @click="addToCart()">
                                {{$t('add-to-cart')}} <span class="arrow"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </vue-final-modal>
    </div>
</template>

<script>
const config = require('../..//config.' + process.env.NODE_ENV).default;
const _ = require('lodash');

import foodItem from "../../components/skybox/food-item.vue"
import Cart from '../../components/skybox/cart/cart.vue'
import { VueFinalModal } from 'vue-final-modal'
import moment from 'moment';
import { marked } from 'marked';
import SmartImage from "../../components/smart-image.vue";
import { mapState } from 'vuex';

export default  {
    components: { foodItem, Cart, VueFinalModal, SmartImage },
    data() {
        return {
            id: -1,
            modalOpened: false,
            quantity: 1,
            selectedProduct: false,
            productTypes: [
                {
                    type: "food",
                    label: "event-view-foods"
                },
                {
                    type: "drink",
                    label: "event-view-drinks"
                },
            ],
            DRINK_CATEGORY: {
                WHISKEY: 'whiskey',
                RUM: 'rum',
                GIN: 'gin',
                TONIC: 'tonic',
                COGNAC: 'cognac',
                VODKA: 'vodka',
                LIQUEUR: 'liqueur',
                TEQUILA: 'tequila',
                CHAMPAGNE: 'champagne',
                WINES: 'wines',
                OTHER: 'other'
            },
            FOOD_CATEGORY: {
                MENU: 'menu',
                SNACK: 'snack',
                SWEETS: 'sweets',
                OTHER: 'other'
            }
        }
    },
    created() {
        this.id = this.$route.params.id;
        this.$store.dispatch('selectBuffet', this.id);
        this.$store.commit('setProductType', 'food');

        if(this.event == undefined || !this.event) {
            return this.$router.push('/events');
        }

        this.$store.commit('setSkyboxTitle', this.event.event.name)
        this.$store.commit('setSkyboxText', '')
        this.$store.commit('setSkyboxLink', '/events')
        this.$store.commit('setSkyboxLinkText', 'event-view-back');

        this.checkActiveFoodNavItem();
        this.checkActiveNavItem();
    },
    computed: {
        ...mapState(['selectedProductType']),
        event() {
            return this.$store.getters.getEventById(this.id)
        },
        foodList() {
            let foods = [];

            if(this.event) {
                foods = this.event.buffetFoods.filter(row => row.food.type == 'food');

                foods = _.orderBy(foods, (item) => item.food.position, ['asc']);
                foods = _.orderBy(foods, (item) => item.featured == false);
            }

            return foods;
        },
        drinkList() {
            let foods = [];
            
            if(this.event) {
                foods = this.event.buffetFoods.filter(row => row.food.type == 'drink');

                foods = _.orderBy(foods, (item) => item.food.position, ['asc']);
                foods = _.orderBy(foods, (item) => item.featured == false);
            }

            return foods;
        },
        featuredProductList() {
            let dataSource = null;
            
            if(this.selectedProductType === 'drink') {
                dataSource = this.drinkList;
            } else {
                dataSource = this.foodList;
            }

            return dataSource.filter(row => row.featured === true);
        },
        eventDate() {
            return moment(this.event.event.date).format('YYYY. MMMM DD.')
        },
        productModalLead() {
            if(this.selectedProduct) {
                return marked.parse(this.selectedProduct.lead);
            }

            return '';
        }
    },
    methods: {
        openModal(product) {
            console.log(product);
            this.modalOpened = true;
            this.selectedProduct = product;
        },
        closeModal() {
            this.modalOpened = false;
            this.selectedProduct = false;
            this.quantity = 1;
        },
        changeQty(way) {
            switch(way) {
                case 'plus':
                    this.quantity++;
                break;

                case 'minus':
                    if(this.quantity > 1) {
                        this.quantity--;
                    }
                break;
            }
        },
        addToCart() {
            let quantity = this.quantity;
            this.$store.dispatch('updateProductQuantityInCart', {product: this.selectedProduct, quantity});

            this.closeModal();
        },
        selectProductType(value) {
            this.$store.commit('setProductType', value);
        },
        getSelectedProductPrice(product) {
            return product.price;
        },
        downloadRecommendations() {
            const newWindow = window.open(`${config.api}buffet-stats-foods/${this.id}`)
            newWindow.document.title = this.$t('title-offers');
        },
        checkActiveFoodNavItem() {
            window.addEventListener("scroll", () => {
                if(this.selectedProductType === 'food') {
                    let fromTop = window.scrollY ;
                    const categories = ['featured-food', ...Object.values(this.FOOD_CATEGORY)];
                    
                    categories.map((link) => {
                        let section = document.getElementById(link);
                        let linkElement = document.getElementById('link-'+link);

                        if(section && section !== null) {

                            if(section) {
                                if (
                                    section.offsetTop <= fromTop &&
                                    section.offsetTop + section.offsetHeight > fromTop
                                ) {
                                    linkElement.classList.add("active");
                                } else {
                                    linkElement.classList.remove("active");
                                }
                            }
                        }
                    });

                }
            });
        },
        checkActiveNavItem() {
            window.addEventListener("scroll", () => {
                if(this.selectedProductType === 'drink') {
                    let fromTop = window.scrollY ;
                    const categories = ['featured-drink', ...Object.values(this.DRINK_CATEGORY)];
                    
                    categories.map((link) => {
                        let section = document.getElementById(link);
                        let linkElement = document.getElementById('link-'+link);

                        if(section && section !== null) {

                            if(section) {
                                if (
                                    section.offsetTop <= fromTop &&
                                    section.offsetTop + section.offsetHeight > fromTop
                                ) {
                                    linkElement.classList.add("active");
                                } else {
                                    linkElement.classList.remove("active");
                                }
                            }
                        }
                    });

                }
            });
        },
        scrollToCategory(category) {
            const section = document.getElementById(category);
            window.scrollTo({
                top: section.offsetTop,
                behavior: 'smooth'
            });
        },
        hasFoodProductInCategory(category) {
            return this.foodList.filter(row => row.food.foodCategory == category).length > 0;
        },
        hasProductInCategory(category) {
            return this.drinkList.filter(row => row.food.category == category).length > 0;
        },
        hasFeaturedItemInType(productType) {
            let dataSource = null;
            if(productType === 'drink') {
                dataSource = this.drinkList;
            } else {
                dataSource = this.foodList;
            }

            return dataSource.filter(row => row.featured == true).length > 0;
        }
    }
}
</script>
<style>
.vfm__container {
    max-width: 650px !important;
}
</style>