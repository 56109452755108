<script>
	import {getImage} from '../functions/helpers';
	const config = require('../config.' + process.env.NODE_ENV).default;

	export default {
		props: {
			img_key: {
				type: String,
				required: true
			},
			size: {
				type: String,
				required: true
			}
		},
		data () {
			return {
				loaded: false,
				error: false,
			}
		},
		watch: {
			img_key() {
				if(this.img_key) {
					this.loaded = this.error = false;
				}
			}
		},
		methods: {
			getImage: getImage(config.cdn),
			onLoad() {
				this.loaded = true;
			},
			onError() {
				this.loaded = true;
				this.error = true;
			}
		}
	}
</script>
<template>
	<div class="smart-image">
		<img
			v-if="!error"
			v-show="img_key && !loaded"
			:src="getImage(img_key)"
			class="placeholder"
		/>
		<img
			v-if="!error"
			v-show="img_key && loaded"
			:src="getImage(img_key, size)"
			@load="onLoad"
			@error="onError"/>
		<div v-else class="broken-image">
			<span class="icon icon-image"></span>
		</div>
	</div>
</template>
<style scoped>
	.smart-image {
		display: flex;
		overflow: hidden;
	}
	img.placeholder {
		filter: blur(2px);
	}
	.broken-image {
		font-size: 42px;
		color: var(--normal-font-color);
		margin: auto;
		height: 100%;
		display: flex;
	}
	.broken-image svg {
		margin: auto;
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: scale-down;
		display: block;
	}
	.broken-image {
		background-color: var(--greey-bg-color);
		width: 100%;
		height: 100%;
		display: flex;
	}
	.broken-image .icon {
		width: 68px;
		margin: auto;
		color: var(--light-font-color);
	}
</style>